<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="closeModal()"
      size="lg"
      class="modal-extended"
    >
      <CRow class="justify-content-center">
        <CCol sm="12" lg="6">
          <CSelect
            :options="ComputedYardOptions"
            v-model="$v.YardId.$model"
            :label="$t('label.yard')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.YardId)"
            :invalid-feedback="errorMessage($v.YardId)"
            @change="YardFilter"
          />
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :fields="fields"
            :items="computedEventList"
            :items-per-page="5"
            column-filter
            pagination
            sorter
            :items-per-page-select="tableText.itemsPerPageText"
            :tableFilter="tableText.tableFilterText"
            :noItemsView="tableText.noItemsViewText"
          >
            <template #Checked-filter>
              <CInputCheckbox
                :label="$t('label.all')"
                custom
                :checked="CheckedAll"
                class="checkbook-filter"
                @update:checked="SelectAll($event)"
              />
            </template>
            <template #Checked="{ item }">
              <td class="text-center">
                <CInputCheckbox
                  custom
                  :checked="item.Checked"
                  class="checkbook-filter"
                  @update:checked="addRemoveEvent(item)"
                />
              </td>
            </template>
          </dataTableExtended>
          <p v-if="$v.CheckedEvents.$error" class="mt-2 text-danger text-center">
            * {{  `${$t('label.must')}${$t('label.toSelect')}${$t('label.atLeast')}`}} 1
          </p>
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="closeModal()"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import EventsPerYardValidations from '@/_validations/event/eventsPerYardValidations';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    loading: false,
    YardId: '',
    YardOptions: [],
    EventList: [],
    CheckedEvents: [],
    OriginCheckedEvents: [],
    CheckedAll: false,
  };
}

//methods
function Submit() {
  try {
    this.isSubmit = true;
		this.loading = true;
    this.$v.$touch();
    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    let TotalCheckedEvents = [];
    let EventJson = [];
    TotalCheckedEvents = [...this.OriginCheckedEvents, ...this.CheckedEvents];
    TotalCheckedEvents.map(item =>{
      if (!EventJson.some(EventJson => EventJson.EventId == item.EventId)) {
        EventJson.push(item);
      }
    })
    let YardEventJson = {
      YardId: this.YardId,
      EventJson:EventJson.map(item =>{
        let ValidateCurrentEvent = this.CheckedEvents.some(CheckedEvents =>
          CheckedEvents.EventId == item.EventId
        );
        return {
          EventId: item.EventId,
          Status: ValidateCurrentEvent ? 1 : 0,
        }
      })
    }
    let metodo = 'POST';
    let ruta = 'YardEvent-insert';
    this.$http.ejecutar( metodo, ruta, YardEventJson, { root: 'YardEventJson' })
      .then((response) => {
				this.closeModal();
				this.$emit('List-Update');
        this.notifySuccess({text: response.data.data[0].Response});
        this.loading = false;
        this.isSubmit = false;
      }).catch((e) => {
        this.isSubmit = false;
        this.loading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.loading = false;
    this.notifyError({text: e});
  }
}

function ConsultServices(YardOptions, YardEventList) {
  this.loading = true;
  let requests = [];
  if (YardOptions) {
    requests[0] = this.$http.ejecutar("GET", "Yard-list", {CompanyBranchId : this.CompanyBranchId, Filter: 'ACTIVO'});
  }
  if (YardEventList) {
    requests[1] = this.$http.ejecutar("GET", "YardEventCargoAll-list", {YardId: this.YardId});
  }
  Promise.all(requests)
    .then((responses) => {
      if (YardOptions) {
        let YardList = responses[0].data.data;
        this.YardOptions  = YardList&&YardList.length!=0 ? YardList : [];
      } 
      if(YardEventList) {
        let YardEventCargoAll = responses[1].data.data;
        this.EventList = YardEventCargoAll&&YardEventCargoAll.length!=0 ? YardEventCargoAll : [];
        this.EventList.map(item =>{
          if (item.EventYardId) {
            this.CheckedEvents.push(item);
          }
        });
        this.OriginCheckedEvents = this.CheckedEvents;
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function YardFilter(event) {
  this.YardId = event.target.value;
  this.EventList = [];
  this.CheckedEvents = [];
  this.OriginCheckedEvents = [];
  if (this.YardId) {
    this.ConsultServices(false, true);
  }
}

function SelectAll(event) {
  if (event) {
    this.CheckedEvents = this.EventList;
  } else {
    this.CheckedEvents = [];
  }
  this.CheckedAll = event;
}

function addRemoveEvent(item) {
  let Event = this.CheckedEvents.some(CheckedEvent => CheckedEvent.EventId == item.EventId)
  if (!Event) {
    //ADD
    this.CheckedEvents.push(item)
  }else{
    //DELETE
    this.CheckedEvents = this.CheckedEvents.filter((CheckedEvent) => CheckedEvent.EventId !== item.EventId);
  }
}

function getdata(val) {
  this.YardId = val.YardId;
  this.ConsultServices(true, true)
  this.$v.$touch();
}

function closeModal() {
  this.YardId = '';
  this.EventList = [];
  this.CheckedEvents = [];
  this.OriginCheckedEvents = [];
  this.$v.$reset();
  this.$emit('Close-Modal');
}

//computed
function ComputedYardOptions() {
  if(this.YardOptions.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.YardOptions.map(function(e){
      chart.push({
        value: e.YardId, 
        label:  e.YardName,
      })    
    })
    return chart;
  }
}

function titleModal() {
  if (this.isEdit) {
    let title = this.EventPerYardItem.YardName || '';
    return `${this.$t('label.edit')} ${this.$t('label.AssociateEventsPerYard')}: ${title}`;
  } else {
    return `${this.$t('label.AssociateEventsPerYard')}`;
  }
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%; text-align:center', filter: false},
    { label: this.$t('label.classification'), key: 'EventGpoName', _classes: 'text-center', _style: 'width:30%;',},
    { label: this.$t('label.Event'), key: 'EventName', _classes: 'text-center', _style: 'width:50%;',},
    { label: '', key: 'Checked', _classes: 'text-center', _style: 'width:10%;', filter: false},
  ];
}

function computedEventList() {
  let _lang = this.$i18n.locale;
    return this.EventList.map((item) => {
      let Checked = this.CheckedEvents.some(CheckedEvent => CheckedEvent.EventId == item.EventId)
      return {
        ...item,
        EventGpoName: _lang=='en' ? item.EventGpoNameEn : item.EventGpoNameEs,
        EventName: _lang=='en' ? item.EventNameEn : item.EventNameEs,
        Checked: Checked,
      };
    });
  }

export default {
  name: 'modal-cargo-type',
  props: { modal: Boolean, isEdit: Boolean, EventPerYardItem: Object },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin,
  ],
  validations: EventsPerYardValidations,
  methods: {
    ConsultServices,
    SelectAll,
    addRemoveEvent,
    closeModal,
    getdata,
    Submit,
    YardFilter,
  },
  computed: {
    titleModal,
    ComputedYardOptions,
    fields,
    computedEventList,
    ...mapState({             
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
    }),
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if (val) {
        if (this.isEdit) {
          this.getdata(this.EventPerYardItem);
        } else {
          this.ConsultServices(true);
        }
      }
    }
  },
 
};
</script>